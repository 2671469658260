<template>
    <el-scrollbar style="height: calc(100vh - 168.5px); overflow-y: auto" >
        <div class="content">
            <common-approvals
                :commonStatus="commonStatus"
                :list="commonList"
                :loadingBtn="loadingBtn"
                @changeCommonStatus="changeCommonStatus"
                @removeHandlerClick="removeHandlerClick"
                @groupDetail="groupDetail"
            >
            </common-approvals>
            <status-approvals
                :list="statusList"
            >
            </status-approvals>
            <all-approvals
                :listQuery="listQuery"
                :tabActiveName="tabActiveName"
                :scrollList="scrollList"
                @handleQuery="handleQuery"
                @addHandlerClick="addHandlerClick"
                @groupDetail="groupDetail"
            >
            </all-approvals>
        </div>
    </el-scrollbar>
</template>
<script>
    import CommonApprovals from './CoworkingOAApproval/CommonApprovals.vue';
    import StatusApprovals from './CoworkingOAApproval/StatusApprovals.vue';
    import AllApprovals from './CoworkingOAApproval/AllApprovals.vue';
    import { minIo } from "@/libs/settings";
    import { STATUS_LIST, randomizationImg } from '@/assets/data/coworkingOAApproval/index.js';
    import { CampusOAModel } from "@/models/CampusOA.js";
    import { mapMutations, mapState } from "vuex";

    export default {
        name: "CoworkingOAApprovalScrollWrapper",
        components: {
            CommonApprovals,
            StatusApprovals,
            AllApprovals
        },
        data() {
            return {
                minIo,
                STATUS_LIST,
                randomizationImg,
                commonStatus: 'edit',
                loadingBtn: false,
                commonList: [],
                listQuery: {
                    schoolId: "",
                    formName: "",
                    status: "1",
                    releaseStatus: "2",
                    userPopulation: "1",
                },
                tabActiveName: '', // 全部审批单默认选中
                statusList: [],
                scrollList: [], // 全部审批单
                activeName: [],
            }
        },
        computed: {
            ...mapState({
                schoolId: (state) => state.schoolId,
            }),
        },
        created() {
            this.init();
        },
        methods: {
            ...mapMutations(["setOAQueryData"]),
            /**
             * @Description: 初始化
             * @Author: 武东超
             * @Date: 2024-09-04 10:21:49
             */            
            init() {
                this.initPageNeedData();
                this.getPageData();
            },
            /**
             * @Description: 页面初始化赋值
             * @Author: 武东超
             * @Date: 2024-09-04 10:20:59
             */            
            initPageNeedData() {
                this.listQuery.schoolId = this.schoolId;
                this.statusList = this.STATUS_LIST;
                console.log(this.statusList ,'this.statusList===')
            },
            /**
             * @Description: 获取页面数据
             * @Author: 武东超
             * @Date: 2024-09-04 10:21:34
             */            
            getPageData() {
                this.getCommonList();
                this.getStatusList();
                this.getAllList();
            },
            /**
             * @Description: 获取常用审批单
             * @Author: 武东超
             * @Date: 2024-09-04 11:35:03
             */            
            getCommonList() {
                this._fet(
                    '/oa/schoolApplyCommon/getCommonRecentForm',
                    { schoolId: this.schoolId }
                )
                .then((res) => {
                    this.handleRes(res, () => {
                        let data = res.data.data;
                        data.commonList.forEach((item) => {
                            if (item.iconName) {
                                item.iconBackgroundColor =
                                    item.iconName.split(",")[1];
                                item.iconLink =
                                    this.minIo +
                                    item.iconName.split(",")[0] +
                                    ".png";
                            }
                        });
                        this.commonList = data.commonList;
                        // this.commonList = this.setBackgImage(this.commonList)
                        console.log(this.commonList,'commonList');
                        return;
                        
                    })
                })
            },
            /**
             * @Description: 获取所有状态列表数据
             * @Author: 武东超
             * @Date: 2024-09-04 15:57:38
             */            
            getStatusList() {
                Promise.all([
                    this.statusParamsList('1'), 
                    this.statusParamsList('2'), 
                    this.statusParamsList('4'),
                    this.statusParamsList('3')
                ]).then((res) => {
                    console.log(res,'res+++')
                    this.statusList.forEach((item, index) => {
                        res.forEach((subItem, subIndex) => {
                            if (index === subIndex) {
                                this.$set(item, 'list', subItem)
                            }
                        })
                    });
                    console.log(this.statusList,'this.statusList')
                })
            },
            /**
             * @Description: 不同的参数获取不同状态列表
             * @Author: 武东超
             * @Date: 2024-09-04 15:59:31
             */            
            statusParamsList(num) {
                let listQuery = {
                    "pageNum": 1,
                    "pageRow": 5,
                    "schoolId": this.schoolId,
                    "queryState": num,
                    "formId":"",
                    "applyId":"",
                    "applyName":"",
                    "startTime":"",
                    "endTime":"",
                    "dataTitle":"",
                    "approveStats":""
                }
                const campusOAModel = new CampusOAModel();
                return new Promise((resolve) => {
                    campusOAModel.getSchoolProcessApplyList(listQuery).then((res) => {
                        this.handleRes(res, () => {
                            resolve(res.data.data.list)
                        })
                    })
                })
                
            },
            /**
             * @Description: 获取所有的审批单
             * @Author: 武东超
             * @Date: 2024-09-04 10:24:17
             */            
            async getAllList() {
                await this._fet(
                    "/oa/schoolFormInfo/permissionListByCondition",
                    this.listQuery,
                ).then((res) => {
                    this.handleRes(res, () => {
                        res.data.data.forEach((item) => {
                            if (item.iconName) {
                                item.iconBackgroundColor =
                                    item.iconName.split(",")[1];
                                item.iconLink =
                                    this.minIo +
                                    item.iconName.split(",")[0] +
                                    ".png";
                            }
                        });
                        // 常用应用添加时增加全部审批单标识
                        res.data.data.forEach(item => {
                            this.$set(item, '_add', false)
                        });
                        this.scrollList = this.arrGroupBy(res.data.data);
                        // this.scrollList = this.setBackgroundImage(this.scrollList)
                        this.activeName = this.scrollList.map((i) => i.groupId);
                        this.tabActiveName = this.scrollList[0].groupId;
                        console.log(this.scrollList ,this.activeName, '---')
                    });
                });
            },
            /**
             * @Description: 整合全部审批单的数据
             * @Author: 武东超
             * @Date: 2024-09-04 10:34:59
             * @param {*} arr
             */            
            arrGroupBy(arr) {
                let map = {};
                let myArr = [];
                for (let i = 0; i < arr.length; i++) {
                    if (!map[arr[i].groupId]) {
                        myArr.push({
                            groupId: arr[i].groupId,
                            label: arr[i].groupName || '暂无名称',
                            list: [arr[i]],
                        });
                        map[arr[i].groupId] = arr[i];
                    } else {
                        for (let j = 0; j < myArr.length; j++) {
                            if (arr[i].groupId === myArr[j].groupId) {
                                myArr[j].list.push(arr[i]);
                                break;
                            }
                        }
                    }
                }
                return myArr;
            },
            /**
             * @Description: 添加随机的背景图片
             * @Author: 武东超
             * @Date: 2024-09-09 17:35:50
             */            
            setBackgroundImage(list) {
                const noCustomizableSet = '#FF9F00';
                list.forEach(item => {
                    item.list.forEach((subItem) => {
                        if (noCustomizableSet === subItem.iconBackgroundColor) {
                            this.$set(subItem, '_randomization', true);
                            this.$set(subItem, 'iconLink', this.getRandomNumber());
                        }
                    })
                })
                return list;
            },
            setBackgImage(list) {
                const noCustomizableSet = '#FF9F00';
                list.forEach(item => {
                    if (noCustomizableSet === item.iconBackgroundColor) {
                        this.$set(item, '_randomization', true);
                        this.$set(item, 'iconLink', this.getRandomNumber());
                    }
                })
                return list;
            },
            /**
             * @Description: 随机背景图片
             * @Author: 武东超
             * @Date: 2024-09-09 17:40:29
             */            
            getRandomNumber() {
                const randomIndex = Math.floor(Math.random() * this.randomizationImg.length);
                return this.randomizationImg[randomIndex];
            },
            /**
             * @Description: 切换审批单状态
             * @Author: 作者
             * @Date: 2024-09-04 11:49:40
             */            
            changeCommonStatus(status) {
                this.commonStatus = status == 'edit' ? 'add' : 'edit';
                if (this.commonStatus === 'edit') {
                    this.setType(false)
                }

                if (this.commonStatus === 'add') {
                    this.setType(true)
                }
               
                if (this.commonList.length !== 0) {
                    this.commonList.forEach((item) => {
                        this.scrollList.forEach((subItem) => {
                            subItem.list.forEach(i => {
                                if (item.id == i.id) {
                                    this.$set(i, '_add', false);
                                }
                            })
                        })
                    })
                }

                this.sendData()
            },
            setType(bool) {
                this.scrollList.forEach((item => {
                    item.list.forEach((subItem) => {
                        this.$set(subItem, '_add', bool);
                    })
                }))
            },
            /**
             * @Description: 说明
             * @Author: 作者
             * @Date: 2024-09-04 14:48:19
             */            
            sendData() {
                if (this.commonStatus == 'add') return;
                this.loadingBtn = true;
                this._fet(
                    "/oa/schoolApplyCommon/save",
                    {
                        schoolId: this.schoolId,
                        formList: this.commonList
                    },
                ).then((res) => {
                    this.handleRes(res, () => {
                        console.log(res,'res')
                        if (res.data.code === '200') {
                            this.getCommonList();
                            this.$message.success('保存成功');
                            this.loadingBtn = false;
                        }
                    })
                })
            },
            /**
             * @Description: 删除审批单
             * @Author: 武东超
             * @Date: 2024-09-04 14:45:07
             */            
            removeHandlerClick(data) {
                this.commonList = this.commonList.filter(item => {
                    return item.id !== data.id;
                });

                this.scrollList.forEach((item) => {
                    item.list.forEach((subItem) => {
                        if (data.id === subItem.id) {
                            this.$set(subItem, '_add', true);
                        }
                    })
                });
            },
            /**
             * @Description: 搜索
             * @Author: 武东超
             * @Date: 2024-09-04 15:32:07
             */            
            handleQuery() {
                console.log(this.listQuery, 'this.listQuery')
                this.getAllList();
            },
            /**
             * @Description: 添加常用审批单
             * @Author: 武东超
             * @Date: 2024-09-04 14:45:22
             * @param {*} data
             */            
            addHandlerClick(data) {
                this.scrollList.forEach((item) => {
                    item.list.forEach((subItem) => {
                        if (data.id === subItem.id) {
                            this.$set(subItem, '_add', false)
                        }
                    })
                });

                this.commonList.unshift(data);
            },
            /**
             * @Description: 跳转详情
             * @Author: 武东超
             * @Date: 2024-09-04 15:20:56
             */            
            groupDetail(data) {
                this.setOAQueryData({
                    formId: data.id,
                    backData: {
                        name: this.$route.name,
                        query: {
                            activeName: '0'
                        }
                    },
                });
                this.$router.push({
                    name: 'CoworkingCampusOAOAApprovalFormDetail',
                    query: {
                        activeName: '1',
                        formName: data.formName
                    }
                });
            },
            /**
             * @Description: 格式化请求结果
             * @Author: 武东超
             * @Date: 2024-09-04 10:37:47
             * @param {*} res
             * @param {*} fn
             */            
            handleRes(res, fn) {
                if (res.data.code === "200") {
                    fn();
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .content {
        box-sizing: border-box;
        padding-right: 12px;
    }
</style>