<template>
    <div class="title">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "Title"
    }
</script>
<style lang="scss" scoped>
    .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 17px;
        color: #48484A;
    }
</style>